/* Gotham Book (Regular) */
@font-face {
    font-family: 'Gotham';
    src: url('../../../../assets/fonts/mayannew/gotham/GothamBook.ttf') format('opentype');
    font-weight: 400;
    /* Regular */
    font-style: normal;
}

/* Gotham Bold */
@font-face {
    font-family: 'Gotham';
    src: url('../../../../assets/fonts/mayannew/gotham/GothamBook.ttf') format('opentype');
    font-weight: 700;
    /* Bold */
    font-style: normal;
}


.tertiary-book {
    font-family: 'Gotham', sans-serif;
    font-weight: 400;
    /* Book */
}

.tertiary-bold {
    font-family: 'Gotham', sans-serif;
    font-weight: 700;
    /* Bold */
}


/* Urbanist Font Styles */
.primary-font-light {
    font-family: 'Urbanist', sans-serif;
    font-weight: 300;
    /* Light */
}

.primary-font-regular {
    font-family: 'Urbanist', sans-serif;
    font-weight: 400;
    /* Regular */
}

.primary-font-medium {
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    /* Medium */
}

.primary-font-bold {
    font-family: 'Urbanist', sans-serif;
    font-weight: 700;
    /* Bold */
}

/* Urbanist Romans Font Styles */
.secondary-font-light {
    font-family: 'Urbanist Romans', sans-serif;
    font-weight: 300;
    /* Light */
}

.secondary-font-regular {
    font-family: 'Urbanist Romans', sans-serif;
    font-weight: 400;
    /* Regular */
}

.secondary-font-medium {
    font-family: 'Urbanist Romans', sans-serif;
    font-weight: 500;
    /* Medium */
}

.secondary-font-bold {
    font-family: 'Urbanist Romans', sans-serif;
    font-weight: 700;
    /* Bold */
}