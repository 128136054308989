.checkout-forms{
    margin-right: 0px;
    font-size: 12px;
    .invalid-feedback{
        font-size: 12px !important;
      }
    label{
        margin-bottom:15px;
        position:relative;
        border-bottom:1px solid #ddd;
        width: 104% !important;
        margin-bottom:0px !important;
    }
    .invalid-feedback{
        font-size: 12px !important;
    }
    input{
        // width:100%;
        // padding:10px 0px;
        margin-top:20px;
        font-size: 14px;
        // border:none !important;
        outline:none !important;
    }
    textarea{
        width:100%;
        padding:10px 0px;
        margin-top:20px;
        font-size: 14px;
        border:none !important;
        outline:none !important;
    }
    .form-group{
        margin-bottom: 0px !important;
    }
    .form-control{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
    .form-control:focus{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
    input:focus{
        border: none;
        outline: none;
    }
    input::placeholder{
        opacity:0;
    }
    textarea::placeholder{
        opacity:0;
    }
    span{
        position:absolute;
        top:0;
        left:0;
        transform:translateY(30px);
        font-size:0.825em;
        transition-duration:300ms;
    }

    .checkout-label{
        color: $light-ash;
    }
    
    label:focus-within > span,
    input:not(:placeholder-shown) + span{
        color:$primary;
        transform:translateY(0px);
    }
    label:focus-within > span,
    textarea:not(:placeholder-shown) + span{
        color:$primary;
        transform:translateY(0px);
    }
}

.btn-primary1{
    padding:8px 0px;
    border-radius: 0px;
    margin-top: 20px;
    font-size: 14px;
    border-radius: 3px !important;
    font-weight: 500;
    // background-color: #121213 !important;
}

.btn-timeslot{
    border-radius: 5px;
    border: 0px;
    background-color: grey;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
    padding: 4px 15px;
    
}

.btn-timeslot1{
    border-radius: 5px;
    border: 0px;
    background-color:$primary;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
    padding: 4px 15px;
    
}


.btn-timeslot:focus {
    background-color:$primary;
  }

  .btn-timeslot.selected{
    color:$primary;
  }

.btn-secondary1{
    padding:8px 20px;
    border-radius: 0px;
    color: $secondary;
    font-size: 14px;
    border-radius: 3px !important;
    background-color: $white !important;
    border: 1px solid $primary !important;
    font-weight: 500;
    color: $primary !important;
}

.btn-secondary1:hover{
    background-color: $primary !important;
    color: $white !important;
}

.customer-container{
    padding: 15px 30px 25px;
    
}
.enableborder{
    background: white;
    font-weight: bold;
    border: 3px solid #0c5798;
    width: 112%;
    color: #0c5798;


}
.disableborder{
    color: gray;
    width: 112%;
    //font-weight: bold;
    border: 3px solid gray;
    background: #f9f9f9 !important;

}
.disableborder:hover{
    color: gray;
    width: 112%;
    //font-weight: bold;
    border: 3px solid gray;
    background: #f9f9f9 !important;
 
}
.enableborder:hover{
    background: white;
    font-weight: bold;
    border: 3px solid #0c5798;
    width: 112%;
    color: #0c5798;
 
 
}
.alignaddressbtn{
    display:flex;
    justify-content: center;
}
@media screen and (max-width:600px) {
    .alignaddressbtn{
        display:unset;
        position: relative;
        top: 5px;
    }
    .disableborder{
        //color: gray;
        width: 65% !important;
        margin-left: 17%
        //font-weight: bold;
        //border: 2px solid gray;
        //background: #f9f9f9 !important;
    
    }
    .enableborder{
        //background: white;
        //font-weight: bold;
        //border: 2px solid #0c5798;
        width: 65% !important;
        margin-left: 17%
        //color: #0c5798;
    
    
    }
    .alignor{
        margin-left: 0px!important;
        margin-top: 6px!important;
    }

}
.alignor{
    margin-left: 20px;
    margin-top: 8px;
}
.custom-link{
    margin-bottom: 10px; 
    color: $menu-color !important; 
    font-weight: bold; 
    cursor: pointer; 
}

.forgot-link{
    color: $menu-color !important; 
    font-weight: bold; 
    cursor: pointer; 
    margin-top: 5px;
}
@media  screen and (min-width:600px) {
.custom-radios{
    margin-left: 20px;
}
}
.custom-radio-text{
    color: $menu-color;
}

.login-link-color{
    color: $primary !important;
}
.checkout_login_details{
    border-right: 1px solid rgb(235, 234, 234); padding-left: 20px; padding-right: 20px;
}
.margin_changetop{
    margin-top: 31px;
}
@media  screen and (max-width:600px) {
    .custom-radios{
        margin-left: 0px;
    }
    .customer-container{
        padding: 0px 0px 20px !important;
        
    }
    .eye-mob{
        position: absolute;
        margin-top: -20%;
        left: 77%;
    }
    .checkout-forms{
        margin-right: 0px;
        font-size: 12px;
        .invalid-feedback{
            font-size: 12px !important;
          }
        label{
            margin-bottom:15px;
            position:relative;
            border-bottom:1px solid #ddd;
            width: 96%;
            margin-bottom:0px !important;
        }
    }
    
}