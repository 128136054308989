@media screen and (max-width: 640px) {
  .mobile-only {
    display: block !important;
  }

  .desktop-only {
    display: none !important;
  }

  // Home Banner section
  .mayannew-home-banner-centered-content {
    .mayannew-content {
      font-size: 20px !important;
    }

    .mayannew-view-more-button {
      font-size: 10px !important;
      padding: 2% 3% !important;
    }
  }

  // Slider section
  .owl-carousel .owl-nav .owl-prev {
    margin-left: 3% !important;
  }

  // Product List
  .mayannew-product-list-container {
    width: 94% !important;
    margin: 3% !important;
  }

  // Reviews section
  .reviews {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    padding: 5px !important;
    margin: 5% 0 !important;

    .profile-icon {
      width: 50px !important;
      height: 50px !important;

      span {
        font-size: 26px !important;
      }
    }

    .reviews-content {
      max-width: 90% !important;

      p {
        font-size: 16px !important;
      }

      .quotes-icon {
        font-size: 30px !important;
      }

      .details p {
        font-size: 14px !important;
      }
    }


  }

  // Sculpture section
  .mayannew-shop-more {
    p {
      font-size: 20px !important;
    }

    button {
      font-size: 10px !important;
      padding: 2% 3% !important;
    }
  }

  // Subscribe section
  .mayannew-subscribe-container {
    .mayannew-subscribe-heading {
      font-size: 17px;
    }

    .mayannew-subscribe-description {
      font-size: 13px;
    }

    .mayannew-email-input-container {
      width: 40%;
    }

    .mayannew-email-input {
      font-size: 10px;
      padding: 3% 4%;
    }
  }

  // Footer section
  .mayannew-footer {

    // Footer Luckme
    .mayannew-container-fluid-footer-two {
      display: block !important;

      .footer-logo {
        text-align: center !important;
      }

      h2 {
        font-size: 17px !important;
      }

      ul,
      p {
        font-size: 16px !important;
        font-family: 'Gotham', sans-serif;
        font-weight: 700;
      }

      ul {
        margin-bottom: 0.5% !important;
      }

      .contact-section div {
        margin-top: 0.5 !important;
      }

      i {
        font-size: 12px !important;
      }
    }

    // Footer
    // .mayannew-container-fluid-footer {
    //   h2 {
    //     font-size: 17px !important;
    //   }

    //   p {
    //     font-size: 13px !important;
    //   }

    //   div {
    //     width: 80% !important;
    //     margin-left: 6% !important;
    //   }
    // }

  }

  .dimension-heading,
  .detail-heading {
    margin-top: 3% !important;
  }

  // Product List
  .mayannew-categories-list {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 25% 7% !important;
    margin-bottom: 15% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;

  }

  .mayannew-filter-container {
    flex-direction: column !important;
  }

  .mayannew-filter-item, .mayannew-clear-option {
    margin-bottom: 5%;
  }

  .mayannew-category-name {
    text-align: center !important;
    padding: 5% !important;
    background-color: #e0e0e0 !important;
    color: #4d4d4d !important;
    cursor: pointer !important;
  }

  .mayannew-category-name.active-category {
    background-color: #4d4d4d !important;
    color: white !important;
  }

  .mayannew-filter-container {
    gap: 6% !important;
    font-weight: bold !important;
  }

  .mayannewclear-option {
    padding: 2% !important;
  }

  .mayannew-category-name,
  .mayannew-material-name,
  .mayannew-weight-name,
  .mayannewclear-option {
    font-size: 18px !important;
  }

  .selectedCategory {
    font-size: 17px !important;
    margin: 5% auto !important;
  }


  .mayannew-product-name {
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    font-size: 15px !important;
  }

  // Product details

  .hod_imagecol {
    width: auto !important;
    padding: 0 !important;
  }

  .detail-preview {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    margin-left: 0%;
  }

  .detail-align {
    width: 100% !important;
  }

  .product-details-wrapper .details-top {
    padding: 5px !important;
  }

  .titleCase-product {
    font-size: 18px !important;
  }

  .product-name .product-name {
    font-size: 35px !important;
    margin-bottom: 5px !important;
  }

  #prodName .product-list-price2-baby {
    font-size: 25px !important;
  }

  .deera-price-row1 span .fa-star-o {
    font-size: 18px !important;
  }

  .mayan_distruck {
    display: flex !important;
    justify-content: space-between !important;
    width: 90% !important;
    margin: auto !important;
    margin-top: 5% !important;
  }

  .linen-login {
    margin-top: 8% !important;
  }

  .mayan-cart-mobile-text {
    font-family: 'Urbanist', sans-serif;
    font-weight: bolder;
    font-size: 25px !important;
  }

  .mayan-cart-mobile-menu {
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    font-size: 20px !important;
  }

  .mayannew-product-name,
  .mayannew-product-price {
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
  }

  .mayannew-product-name {
    font-size: 16px !important;
  }

  .mayannew-product-price {
    font-size: 15px !important;
  }

  .mayannew-product-dimensions, .mayannew-product-actions {
    font-weight: 500;
    font-size: 15px !important;
  }


  .Taz_wid_cart .mobile-text {
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    font-size: 22px !important;
    margin-bottom: 8% !important;

    .Kat_clear_btn {
      font-family: 'Urbanist', sans-serif;
      font-weight: 500;

    }
  }

  .Taz_wid_cart {
    hr {
      margin-bottom: 5% !important;
    }

    .mayan-prod-cart-details span {
      margin-left: 0 !important;
    }

    .mayan_productName {
      font-family: 'Urbanist', sans-serif;
      font-weight: 700;
      font-size: 15px !important;
    }

    .cost-trash-posi {
      position: relative;
    }

    .cost-deera {
      font-size: 14px;
    }
  }

  // .rounder-corner-nav .owl-theme .owl-nav .owl-next {
  //   width: 0 !important;
  // }



  app-product-box-one {
    margin-left: 9% !important;
  }

  // My Accounts
  #pills-tab h6 {
    font-family: 'Urbanist', sans-serif;
    font-weight: 700;
  }

  .profile-page {
    font-size: 14px !important;

    .heading {
      font-weight: 700;
      font-size: 20px !important;
    }

  }

  // Header-two 
  .signin-signup .text {
    font-size: 15px !important;
  }

  // About-us
  .mayannew-about-history {
    display: flex;
    /* flex-wrap: wrap; */
    background-color: #333;
    color: #fff;
    padding: 40px 85px;
    /* gap: 20px; */
    margin: 0 auto;
    justify-content: center;
    width: 100%;
  }

  .mayannew-about-history-content {
    flex: 1;
    max-width: 60%;
    /* Adjust content width */
  }

  .mayannew-about-history-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .mayannew-about-history-content p {
    line-height: 1.8;
    font-size: 14px;
    margin-bottom: 15px;
    color: #7f7979;
    font-weight: 600;
  }

  .mayannew-history-read-more {
    background-color: #fff;
    color: #333;
    padding: 10px 20px;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    /* font-weight: 700; */
    box-shadow: 0 4px 10px #0000004d;
    transition: all .3s ease;
    width: 26%;
    margin-top: 3%;
  }

  .mayannew-history-read-more:hover {
    background-color: #555555;
    /* Slightly darker hover effect */
    color: #ffffff;
    /* White text on hover */
  }

  .mayannew-about-history-image {
    flex: 1;
    max-width: 40%;
    /* Adjust image container width */
    text-align: center;
  }

  .mayannew-about-history-image-img {
    width: 80%;
    /* Ensure image is responsive */
    height: auto;
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Subtle shadow */
  }

  .mayannew-related-section {
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    color: #fff;
    padding: 40px;
    gap: 20px;
    /* border-radius: 10px; */
    box-shadow: 0 4px 10px #0000004d;
  }

  .mayannew-about-related-image {
    flex: 1;
    max-width: 40%;
    /* Image container width */
    display: flex;
    justify-content: center;
    /* Center image horizontally */
    align-items: center;
    /* Center image vertically */
  }

  .mayannew-about-related-image-img {
    width: 70%;
    /* Make the image responsive */
    height: auto;
    border-radius: 10px;
    /* Rounded image corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Subtle shadow for the image */
  }

  .mayannew-about-related-content {
    flex: 1;
    max-width: 55%;
    /* Content container width */
  }

  .mayannew-about-related-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff;
    /* White heading color */
  }

  .mayannew-about-related-content p {
    line-height: 1.8;
    font-size: 16px;
    margin-bottom: 15px;
    color: #cccccc;
    /* Slightly lighter text for readability */
  }

  .mayannew-related-read-more {
    background-color: #333333;
    /* White background for the button */
    color: #ffffff;
    /* Dark text for contrast */
    padding: 10px 20px;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    /* font-weight: 700; */
    box-shadow: 0 4px 10px #0000004d;
    transition: all .3s ease;
    width: 26%;
    margin-top: 3%;
  }

  .mayannew-related-read-more:hover {
    background-color: #555555;
    /* Darker grey hover effect */
    color: #ffffff;
    /* White text on hover */
  }



  .mayannew-about-sculpture-section {
    background-color: #333333;
    /* Dark background */
    color: #f4f4f4;
    /* Light text color */
    padding: 40px 20px;
    /* Padding around the section */
    font-family: 'Arial', sans-serif;
    /* Font family for the section */
    line-height: 1.8;
    /* Line spacing for better readability */
  }

  .mayannew-sculpture-container {
    max-width: 1200px;
    /* Limit width of the container */
    margin: 0 auto;
    /* Center the container */
  }

  .mayannew-sculptures-section {
    display: flex;
  }

  .mayannew-sculpture-intro {
    margin-bottom: 40px;
    /* Space below the introduction section */
  }

  .mayannew-sculpture-text {
    margin-bottom: 16px !important;
    text-align: justify;
    font-size: 15px;
  }

  .mayannew-sculpture-heading {
    font-size: 24px;
    /* Heading font size */
    font-weight: bold;
    /* Bold heading text */
    margin-bottom: 20px;
    /* Space below the heading */
  }

  .mayannew-sculptures {
    list-style: none;
    /* Remove bullet points */
    padding: 0;
    /* Remove padding from the list */
    margin: 0 0 40px 0;
    /* Space below the list */
  }

  .mayannew-sculptures li {

    font-size: 16px;
    /* Font size for the list items */
  }

  .mayannew-sculpture-img-container {
    text-align: center;
    /* Center the image */
    margin-top: 20px;
    /* Space above the image */
  }

  .mayannew-sculpture-image {
    max-width: 100%;
    /* Ensure the image is responsive */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 10px;
    /* Round the corners of the image */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Add a subtle shadow */
  }

  // About-us
  .mayannew-about-history {
    display: flex;
    flex-direction: column;
    background-color: #333;
    color: #fff;
    padding: 21px 22px;
    margin: 0 auto;
    justify-content: center;
    width: 100%;
}
 
 
  .mayannew-about-history-content {
    flex: 1;
   
  }
 
  .mayannew-about-history-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
 
  .mayannew-about-history-content p {
    line-height: 1.8;
    font-size: 14px;
    margin-bottom: 15px;
    color: #7f7979;
    font-weight: 600;
}
  .mayannew-history-read-more {
    background-color: #fff;
    color: #333;
    padding: 10px 20px;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    /* font-weight: 700; */
    box-shadow: 0 4px 10px #0000004d;
    transition: all .3s ease;
    width: 60%;
    margin-top: 3%;
}
 
  .mayannew-history-read-more:hover {
    background-color: #555555; /* Slightly darker hover effect */
    color: #ffffff; /* White text on hover */
  }
 
  .mayannew-about-history-image {
    flex: 1;
   order:-1;
    text-align: center;
  }
 
  .mayannew-about-history-image-img {
    width: 100%; /* Ensure image is responsive */
    height: auto;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow */
  }
  .mayannew-related-section {
    display: flex;
   flex-direction: column;
    background-color: #ffffff;
    color: #fff;
    padding: 40px;
    gap: 20px;
    /* border-radius: 10px; */
    box-shadow: 0 4px 10px #0000004d;
}
 
  .mayannew-about-related-image {
    flex: 1;
    max-width: 40%; /* Image container width */
    display: flex;
    justify-content: center; /* Center image horizontally */
    align-items: center; /* Center image vertically */
  }
 
  .mayannew-about-related-image-img {
    width: 70%; /* Make the image responsive */
    height: auto;
    border-radius: 10px; /* Rounded image corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for the image */
  }
 
  .mayannew-about-related-content {
    flex: 1;
 
  }
 
  .mayannew-about-related-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff; /* White heading color */
  }
 
  .mayannew-about-related-content p {
    line-height: 1.8;
    font-size: 16px;
    margin-bottom: 15px;
    color: #cccccc; /* Slightly lighter text for readability */
  }
 
  .mayannew-related-read-more {
    background-color: #333333; /* White background for the button */
    color: #ffffff; /* Dark text for contrast */
    padding: 10px 20px;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    /* font-weight: 700; */
    box-shadow: 0 4px 10px #0000004d;
    transition: all .3s ease;
    width: 60%;
    margin-top: 3%;
  }
 
  .mayannew-related-read-more:hover {
    background-color: #555555; /* Darker grey hover effect */
    color: #ffffff; /* White text on hover */
  }
 
 
 
  .mayannew-about-sculpture-section {
    background-color: #333333; /* Dark background */
    color: #f4f4f4; /* Light text color */
    padding: 40px 20px; /* Padding around the section */
    font-family: 'Arial', sans-serif; /* Font family for the section */
    line-height: 1.8; /* Line spacing for better readability */
  }
 
  .mayannew-sculpture-container {
    max-width: 1200px; /* Limit width of the container */
    margin: 0 auto; /* Center the container */
  }
  .mayannew-sculptures-section{
    display: flex;
    flex-direction: column;
  }
  .mayannew-sculpture-intro {
    margin-bottom: 40px; /* Space below the introduction section */
  }
 
  .mayannew-sculpture-text {
    margin-bottom: 16px !important;
    text-align: justify;
    font-size: 15px;
}
 
  .mayannew-sculpture-heading {
    font-size: 24px; /* Heading font size */
    font-weight: bold; /* Bold heading text */
    margin-bottom: 20px; /* Space below the heading */
  }
 
  .mayannew-sculptures {
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove padding from the list */
    margin: 0 0 40px 0; /* Space below the list */
    order: 1;
  }
 
  .mayannew-sculptures li {
   
    font-size: 16px; /* Font size for the list items */
  }
 
  .mayannew-sculpture-img-container {
    text-align: center; /* Center the image */
    margin-top: 20px; /* Space above the image */
  }
 
  .mayannew-sculpture-image {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Round the corners of the image */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Add a subtle shadow */
  }

}

@media screen and (min-width: 641px) {
  .desktop-only {
    display: block !important;
  }

  .mobile-only {
    display: none !important;
  }

  .checkout-forms {
    .mayannew-location-row {
      .mayannew-location-col {
        width: 50% !important;
      }
    }
  }

  .mayannew-product-name {
    font-size: 13px !important;
  }

  .delivery-option {
    h6 {
      font-family: 'Urbanist', sans-serif;
      font-weight: 600;
    }

    input {
      font-family: 'Urbanist', sans-serif;
      font-weight: 700;
    }

    button {
      font-family: 'Urbanist', sans-serif;
      font-weight: 400;
    }
  }

  .mayan_distruck {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }

  .mayan-truck {
    width: 25%;

    p {
      font-family: 'Urbanist', sans-serif;
      font-weight: 400;
    }
  }

  .mayan-truck-image {
    width: 65%;
  }

  .Taz_wid_cart {
    width: 75% !important;
    margin: 0 auto !important;
  }


  .modal-dialog {
    max-width: 100% !important;
  }

  .modal-dialog_may {
    transform: translateY(111%) !important;
  }

  .modal-header {
    .row {
      flex-wrap: nowrap !important;
    }
  }

  .mayan-order-details {
    display: flex !important;
    flex-wrap: nowrap !important;

    .col-md-2 {
      flex: 0 0 16.66666667% !important;
      max-width: 16.66666667% !important;

    }

    .col-md-4 {
      flex: 0 0 33.33333333% !important;
      max-width: 33.33333333% !important;
    }
  }

  .mayannew-order-confirmation-row {
    flex-wrap: nowrap !important;
  }

  .mayannew-order-confirmation-col1 {
    flex-wrap: nowrap !important;
  }

  .mayannew-order-box {
    margin-right: 2% !important;
  }

  .login-card {

    // width: 70% !important;
    .row {
      flex-wrap: nowrap !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .customer-container,
    .checkout-forms {
      // width: 70% !important;
    }
  }

  // My Cart
  app-cart-page {
    font-family: 'Urbanist', sans-serif;

    td {
      h5 {
        font-weight: 600;
      }

      .cost {
        font-weight: 500;
        font-size: 16px;
      }
    }

    .total-price {
      font-size: 18px;
    }

    .btn-primary {
      font-size: 14px;
      font-weight: 500;
    }
  }

  app-mini-cart {
    font-family: 'Urbanist', sans-serif;

    h6,
    .btn-primary,
    .btn-secondary {
      font-size: 13px;
    }

    .col-10 {
      font-size: 15px;
    }
  }

  .clickable-tag {
    margin-top: -5% !important;
  }

  .accordian-container {
    .col-md-8 {
      max-width: 66.66666667% !important;
    }

    .col-md-4 {
      max-width: 33.33333333% !important;
    }
  }

  // About us
  .mayannew-about-history {
    display: flex;
    /* flex-wrap: wrap; */
    background-color: #333;
    color: #fff;
    padding: 40px 85px;
    /* gap: 20px; */
    margin: 0 auto;
    justify-content: center;
    width: 100%;
}
 
  .mayannew-about-history-content {
    max-width: 60%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
 
  .mayannew-about-history-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
 
  .mayannew-about-history-content p {
    line-height: 1.8;
    font-size: 14px;
    margin-bottom: 15px;
    color: #7f7979;
    font-weight: 600;
}
  .mayannew-history-read-more {
    background-color: #fff;
    color: #333;
    padding: 10px 20px;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    /* font-weight: 700; */
    box-shadow: 0 4px 10px #0000004d;
    transition: all .3s ease;
    width: 26%;
    margin-top: 3%;
}
 
  .mayannew-history-read-more:hover {
    background-color: #555555; /* Slightly darker hover effect */
    color: #ffffff; /* White text on hover */
  }
 
  .mayannew-about-history-image {
    flex: 1;
    max-width: 40%; /* Adjust image container width */
    text-align: center;
  }
 
  .mayannew-about-history-image-img {
    width: 80%; /* Ensure image is responsive */
    height: auto;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow */
  }
  .mayannew-related-section {
    display: flex
;
    flex-wrap: wrap;
    background-color: #ffffff;
    color: #fff;
    padding: 40px;
    gap: 20px;
    /* border-radius: 10px; */
    box-shadow: 0 4px 10px #0000004d;
}
 
  .mayannew-about-related-image {
    flex: 1;
    max-width: 40%; /* Image container width */
    display: flex;
    justify-content: center; /* Center image horizontally */
    align-items: center; /* Center image vertically */
  }
 
  .mayannew-about-related-image-img {
    width: 70%; /* Make the image responsive */
    height: auto;
    border-radius: 10px; /* Rounded image corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for the image */
  }
 
  .mayannew-about-related-content {
    /* flex: 1; */
    max-width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 
  .mayannew-about-related-content h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333333;
  }
 
  .mayannew-about-related-content p {
    line-height: 1.8;
    font-size: 16px;
    margin-bottom: 15px;
    color: #808080;
  }
 
  .mayannew-related-read-more {
    background-color: #333333; /* White background for the button */
    color: #ffffff; /* Dark text for contrast */
    padding: 10px 20px;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    /* font-weight: 700; */
    box-shadow: 0 4px 10px #0000004d;
    transition: all .3s ease;
    width: 26%;
    margin-top: 3%;
  }
 
  .mayannew-related-read-more:hover {
    background-color: #555555; /* Darker grey hover effect */
    color: #ffffff; /* White text on hover */
  }
 
 
 
  .mayannew-about-sculpture-section {
    background-color: #333333; /* Dark background */
    color: #f4f4f4; /* Light text color */
    padding: 40px 20px; /* Padding around the section */
    font-family: 'Arial', sans-serif; /* Font family for the section */
    line-height: 1.8; /* Line spacing for better readability */
  }
 
  .mayannew-sculpture-container {
    max-width: 1200px; /* Limit width of the container */
    margin: 0 auto; /* Center the container */
  }
  .mayannew-sculptures-section{
    display: flex;
  }
  .mayannew-sculpture-intro {
    margin-bottom: 40px; /* Space below the introduction section */
  }
 
  .mayannew-sculpture-text {
    margin-bottom: 16px !important;
    text-align: justify;
    font-size: 15px;
}
 
  .mayannew-sculpture-heading {
    font-size: 24px; /* Heading font size */
    font-weight: bold; /* Bold heading text */
    margin-bottom: 20px; /* Space below the heading */
  }
 
  .mayannew-sculptures {
    list-style: none; /* Remove bullet points */
    padding: 0; /* Remove padding from the list */
    margin: 0 0 40px 0; /* Space below the list */
  }
 
  .mayannew-sculptures li {
   
    font-size: 15px; /* Font size for the list items */
  }
 
  .mayannew-sculpture-img-container {
    text-align: center; /* Center the image */
    margin-top: 20px; /* Space above the image */
  }
 
  .mayannew-sculpture-image {
    width: 70%; /* Ensure the image is responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Round the corners of the image */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Add a subtle shadow */
  }
}

@media (min-width: 641px) and (max-width: 950px) {

  // Header-section
  .mayannew-navbar-row {
    .mayannew-logo {
      width: 40% !important;
    }

    .mayannew-menu-items {
      gap: 20px !important;
      margin-left: -10% !important;
    }

    .mayannew-menu-link {
      font-size: 9px !important;
    }

    .mayannew-search-bar-container {
      .mayannew-search-button {
        margin-left: -60% !important;
      }

      .mayannew-search-icon {
        font-size: 9px !important;
      }
    }
  }

  .mayannew-product-image2 {
    max-width: 110px !important;
    height: 140px !important;
  }

  // .product-image {
  //   height: 140px !important;
  // }
}

@media (min-width: 641px) and (max-width: 780px) {

  // Header-section
  .mayannew-navbar-row {
    .logo {
      width: 30% !important;
    }

    .menu-items {
      gap: 10px !important;
      margin-left: -35% !important;
    }

    .mayannew-menu-link {
      font-size: 9px !important;
    }

    .mayannew-search-bar-container {
      width: 49% !important;
    }

  }
}

@media (min-width: 950px) and (max-width: 1120px) {

  // Header section
  .mayannew-navbar-row {
    .mayannew-logo {
      width: 40% !important;
    }

    .menu-items {
      gap: 30px !important;
    }

    .mayannew-menu-link {
      font-size: 11px !important;
    }

    .mayannew-search-icon {
      font-size: 11px !important;
    }
  }
}

@media (min-width: 641px) and (max-width: 851px) {

  // Slider section
  .mayannew-product-name {
    font-size: 10px !important;
  }

  .mayannew-icon-button,
  .mayannew-product-price,
  .mayannew-product-dimensions {
    font-size: 8px !important;
  }

  // Scultpure section 
  .mayannew-shop-more {
    p {
      font-size: 21px !important;
    }
  }

  // Product Details
  .ngxImageZoomThumbnail {
    width: 350px !important;
    height: 530px !important;
  }

  .detail-align {
    margin: auto 3% !important;
  }

}

@media (min-width: 851px) and (max-width: 1170px) {

  // Slider section
  .mayannew-product-name {
    font-size: 12px !important;
  }

  .mayannew-icon-button,
  .mayannew-product-price,
  .mayannew-product-dimensions {
    font-size: 10px !important;
  }

  // Scultpure section 
  .mayannew-shop-more {
    p {
      font-size: 25px !important;
    }
  }

  // Product Details
  .detail-align {
    margin: auto 5% !important;
  }
}

@media (min-width: 641px) and (max-width: 1105px) {

  // Banner 3 section
  .mayannew-philosophy-content {
    h2 {
      font-size: 18px !important;
    }

    div {
      width: 95% !important;
    }
  }

  // Review  section
  .profile-icon {
    width: 50px !important; //Need to update in percentage
    height: 50px !important; //Need to update in percentage

    span {
      font-size: 22px !important;
    }
  }

  // Reviews section
  .reviews-content {
    p {
      font-size: 15px !important;
    }

    .quotes-icon {
      font-size: 26px !important;
    }

    .details {
      font-size: 12px !important;
    }
  }

  // Footer section
  .mayannew-container-fluid-footer-two {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5%;
    padding: 1% 6%;
    font-size: 18px;

    .footer-logo {
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: 2.5%;
    }

    .footer-mayan-handicrafts,
    .footer-help {
      flex: none;
      width: 48%;
      margin-bottom: 1.5%;
    }

    .footer-shop-by,
    .footer-contact {
      flex: none;
      width: 48%;
      margin-bottom: 1.5%;
      font-size: 16px !important;
    }

    .footer-social-media a {
      margin-right: 1rem;
      border-radius: 50%;
      background-color: #4d4d4d;
      padding: 0.5rem;
      font-size: 14px;
    }
  }


}

@media (min-width: 641px) and (max-width: 745px) {

  // Collections section
  .mayannew-collection-grid {
    max-width: 98% !important;
    margin: 0 1% !important;
    height: 58vh !important;
  }

  // History section
  .mayannew-history-content {
    line-height: 100% !important;
    width: 60% !important;
    top: 37% !important;

    h1 {
      font-size: 17px !important;
      margin: 0 !important;
    }

    p {
      font-size: 11px !important;
    }
  }

  .history-footer {
    width: 40% !important;
    top: 70%;

    p,
    span {
      font-size: 12px !important;

    }
  }
}



@media (min-width: 641px) and (max-width: 745px) {

  // Banner section
  .mayannew-home-banner-centered-content {
    top: 34% !important;

    .mayannew-content {
      font-size: 22px !important;
    }
  }


  // Banner 3 section
  .mayannew-philosophy-content {
    height: 50% !important;

    div {
      width: 95% !important;

      p {
        font-size: 14px !important;
      }
    }
  }

  .mayannew-home-banner-centered-content {
    .mayannew-content {
      font-size: 22px !important;
    }

    .mayannew-view-more-button {
      font-size: 9px !important;
    }
  }

  // Product List
  .mayannew-product-list-container {


    .mayannew-category-name,
    .mayannew-material-name,
    .mayannew-weight-name,
    .mayannewclear-option {
      font-size: 13px !important;
    }

    .mayannew-material-options,
    .mayannew-weight-options {
      font-size: 12px !important;
    }

    .selectedCategory {
      font-size: 17px !important;
    }
  }

}

@media (min-width: 745px) and (max-width: 900px) {

  // Banner section
  .mayannew-home-banner-centered-content {
    top: 40% !important;

    .mayannew-content {
      font-size: 25px !important;
    }
  }

  // Banner 3 section
  .mayannew-philosophy-content {
    height: 45% !important;

    div {
      width: 95% !important;
    }

    p {
      font-size: 16px !important;
    }
  }

  // Collection section
  .mayannew-collection-grid {
    max-width: 98% !important;
    margin: 0 1% !important;
    height: 68vh !important;
  }

  // History section
  .mayannew-history-content {
    line-height: 120% !important;
    width: 55% !important;
    top: 35% !important;

    h1 {
      font-size: 16px !important;
      margin: 0 !important;
    }

    p {
      font-size: 12px !important;
    }
  }

  .history-footer {
    width: 40% !important;
    top: 70%;

    p,
    span {
      font-size: 12px !important;
    }
  }

  // Product List
  .mayannew-product-list-container {

    .mayannew-category-name,
    .mayannew-material-name,
    .mayannew-weight-name,
    .mayannew-clear-option {
      font-size: 14px !important;
    }

    .mayannew-material-options,
    .mayannew-weight-options {
      font-size: 12px !important;
    }

    .selectedCategory {
      font-size: 18px !important;
    }
  }

}

@media (min-width: 901px) and (max-width: 1055px) {

  // Banner section
  .mayannew-home-banner-centered-content {
    top: 45% !important;

    .mayannew-content {
      font-size: 25px !important;
    }
  }

  // History section
  .mayannew-history-content {
    line-height: 25px !important;
    width: 60% !important;
    top: 40% !important;

    h1 {
      font-size: 22px !important;
      margin: 0 !important;
    }

    p {
      font-size: 14px !important;
    }
  }

  .history-footer {
    width: 40% !important;
    top: 70%;

    p,
    span {
      font-size: 16px !important;
    }
  }
}


@media (min-width: 901px) and (max-width: 1104px) {

  .mayannew-history-content {
    top: 37% !important;

    h1 {
      font-size: 26px !important;
      margin: 0 !important;
    }

    p {
      font-size: 15px !important;
    }
  }

  // Banner 3 section
  .mayannew-philosophy-content {
    height: 37% !important;

    p {
      font-size: 17px !important;
    }

  }

  .mayannew-collection-grid {
    max-width: 98% !important;
    margin: 0 1% !important;
    height: 85vh !important;
  }

}

@media (min-width: 641px) and (max-width: 1020px) {

  // Header-two
  .mayannew-header-two * {
    font-size: 7px !important;
  }
}

@media (min-width: 1105px) and (max-width: 1175px) {
  .mayannew-collection-grid {
    max-width: 98% !important;
    margin: 0 1% !important;
    height: 93vh !important;
  }
}

// Product-list 
@media (min-width: 451px) and (max-width: 640px) {
  .mayannew-productsList-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 450px) {
  .mayannew-productsList-grid {
    grid-template-columns: 1fr !important;
    padding: 0 !important;
    margin: 5% !important;
    gap: 1% !important;
  }

  // .product-card {
  //   margin: 0 auto !important;
  // }
}


// Mayan New media query for width of 1440px(15 inch) and above
@media screen and (min-width:1440px) {

  app-root main,
  .mayannew-product-list {
    margin: 0 auto !important;
    max-width: 1440px !important;
  }

  // Login
  .bg-login {
    min-height: 55vh !important;
  }

  .linen-login {

    .col-md-10,
    .col-lg-10 {
      width: 85% !important;
    }
  }

  // Header-Two
  .mayannew-header-two {
    width: 98%;

    .mayannew-sticky-header {
      width: 90% !important;
      max-width: 1440px !important;
      margin: 0 auto !important;

      span,
      a,
      i {
        font-size: 14px !important;
      }
    }
  }

  // Home Banner
  .mayannew-navbar-container {
    margin: auto !important;
    width: 100% !important;
    max-width: 1440px;
    // top: 5% !important;

    .mayannew-menu-link {
      font-size: 16px !important;
    }
  }

  .mayannew-home-banner-centered-content {
    top: 55% !important;
  }

  // Collections section
  .mayannew-collections {
    margin-bottom: 5% !important;

    h2 {
      font-size: 36px !important;
    }
  }

  .mayannew-collection-grid {
    max-width: 100% !important;
    margin: 0 !important;
    height: 100% !important;

    span {
      font-size: 16px !important;
    }
  }

  // History section 
  .mayannew-history-content {

    h1 {
      font-size: 35px !important;
      margin-bottom: 4% !important;
    }

    p {
      font-size: 20px !important;
    }
  }

  .mayannew-history-footer {
    width: 30% !important;

    p {
      font-size: 20px !important;

      span {
        font-size: 20px !important;
      }
    }
  }

  // Philosophy section
  .mayannew-philosophy-content {
    height: 45% !important;
    padding: 2% !important;

    h2 {
      font-size: 30px !important;
    }

    p {
      font-size: 24px !important;
    }

    p:nth-of-type(2) {
      margin-top: 16px !important;
    }
  }

  // Divine Collections Slider
  .divine-collections-title {
    font-size: 38px !important;
  }


  .mayannew-product-card {
    .mayannew-product-name {
      font-size: 17px !important;
    }

    .mayannew-product-price {
      font-size: 17px !important;
    }

    .mayannew-product-actions-container {
      .mayannew-product-dimensions {
        font-size: 15px !important;
      }

      .mayannew-product-actions {
        font-size: 20px !important;
      }
    }

  }

  .mayannew-filter-item {
    margin-right: 1% !important;
  }

  // Reviews 
  .reviews-content {
    .review {
      font-size: 23px !important;
    }

    .review:not(:has(span)) {
      font-size: inherit;

    }

    .details {
      font-size: 22px !important;
    }
  }

  // Sculpture
  .mayannew-shop-more {
    width: 100% !important;
  }

  // Subscribe Footer
  .mayannew-subscribe-container {
    width: 100% !important;

    .mayannew-subscribe-heading {
      font-size: 25px !important;
    }

    .mayannew-subscribe-description {
      font-size: 20px !important;
    }

    .mayannew-email-input-container {
      width: 35% !important;
      margin-top: 1% !important;
    }

    .mayannew-email-input {
      padding: 2% 3% !important;
      font-size: 18px !important;
    }
  }

  // Product Details
  .product-details-page,
  .mayannew-related-collections {
    max-width: 1440px !important;
    margin: auto !important;
    width: 100% !important;
  }

  .pro_detail {
    min-height: auto !important;
  }

  .ngxImageZoomThumbnail {
    width: 650px !important;
    height: 750px !important;
  }

  .image-preview-product-taazah {
    width: 120px !important;
    height: 160px !important;
  }

  .ngxImageZoomFullContainer.ngxImageZoomLensEnabled {
    border: none !important;
  }

  .pro_detail {
    width: 35% !important;
  }

  .titleCase-product {
    font-size: 28px !important;
  }

  .product-name .product-name {
    font-size: 24px !important;
  }

  #prodName .product-list-price2-baby {
    font-size: 18px !important;
  }

  .deera-price-row1 span .fa-star-o {
    font-size: 23px !important;
  }

  .dimension-heading,
  .detail-heading,
  .heading {
    font-size: 18px !important;

    label,
    span {
      font-size: 18px !important;
    }
  }

  .delivery-btn {
    font-size: 19px !important;
  }

  .delivery-text,
  .guide-headings span {
    font-size: 18px !important;
  }

  .mayan-truck p {
    font-size: 17px !important;
  }

  .mayannew-related-collections {
    margin-top: 8% !important;
  }

  // Footer-two
  .mayannew-footer {
    .mayannew-container-fluid-footer-two {
      // width: 100% !important;
      // max-width: 1440px !important;
      margin: 0 auto !important;
    }

    // .logo {
    //   width: 60% !important;
    //   height: 30% !important;
    // }

    .footer-title,
    .footer-link,
    .footer-text {
      font-size: 20px !important;
    }

    .social-media {
      .social-icon {
        font-size: 21px !important;

        i {
          font-size: 20px !important;
        }
      }
    }
  }

  .mayannew-category-name,
  .mayannew-material-name,
  .mayannew-weight-name,
  .mayannewclear-option,
  .selectedCategory {
    font-size: 20px !important;
  }

  #material option,
  #weight option{
    font-size: 22px !important;
  }

  .owl-carousel .owl-nav,
  .owl-carousel .owl-dots {
    position: absolute !important;
    top: 50% !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    transform: translateY(-50%) !important;
  }

  .fa-angle-left:before,
  .fa-angle-right:before {
    font-size: 25px !important;
  }

  #breadCrumbProductDetails {
    font-size: 20px !important;
  }

  .product-list-price2-baby {
    margin-bottom: 5% !important;
  }

  app-cart-page {
    .cart-table {
      h5 {
        font-size: 22px !important;
      }

      .cost {
        font-size: 20px !important;
      }
    }

    .total-price {
      font-size: 24px !important;
    }
  }



}

.logo-section {
  margin-left: 0 !important;
}


//Old existing css