// Slider section
.owl-carousel {
    display: flex;
    overflow: hidden;
    position: relative;
}

.owl-carousel .owl-stage {
    display: flex;
    transition: transform 0.25s ease;
}

.owl-carousel .owl-stage .owl-item {
    flex-shrink: 0;
    width: auto;
}

.owl-carousel .owl-nav,
.owl-carousel .owl-dots {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.owl-carousel .owl-nav button,
.owl-carousel .owl-dots button {
    background-color: transparent !important;
    border: none !important;
    cursor: pointer;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
    color: #000 !important;
}

.owl-carousel .owl-dots {
    pointer-events: none;
    bottom: -30px;
    justify-content: center;
}

.owl-carousel .owl-dots .owl-dot {
    height: 10px !important;
    width: 10px !important;
    margin: 0 5px !important;
    background-color: #ccc !important;
    border-radius: 50% !important;
    transition: background-color 0.3s ease !important;
}

.owl-carousel .owl-dots .owl-dot.active {
    background-color: #4d4d4d !important;
}

.owl-carousel .owl-item img {
    width: 100%;
}

.owl-carousel .owl-item .product-list-description {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.owl-carousel .owl-item .product-list-description .mayan-product-list-price1 {
    font-size: 16px;
    font-weight: bold;
    color: #4d4d4d;
    margin-top: 10px;
}

.owl-carousel .owl-item .product-list-description .mayan_ftre_name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.mayannew-product-image {
    border-radius: 10px;
    object-fit: cover;
}

.product-details {
    margin-top: 10px;
}

.detail-align {
    width: 30% !important;
}

// Review section
.reviews {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2% 4%;
    color: #4d4d4d;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
}

.profile-icon {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #4d4d4d;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 36px;
    margin-right: 2.5%;
}

.reviews-content {
    max-width: 60%;
    text-align: left;
}

.review-text {
    font-style: italic;
    font-size: 18px;
    line-height: 140%;
    font-weight: light;
    margin: 2% auto;
    text-align: justify;
}

.quotes-icon {
    font-size: 42px;
    font-weight: bold;
    color: #4d4d4d;
}

.quotes-icon.top {
    vertical-align: top;
}

.quotes-icon.bottom {
    vertical-align: bottom;
    margin-top: 2%;
}

.details {
    margin-top: 2.5%;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

.details p {
    margin: 0;
}

.details p+p {
    margin-top: 1%;
}

// Banner Search section 
.autocomplete-items1 {
    margin-left: 70% !important;
    top: 50% !important;
}

// Subcribe Section
.mayannew-subscribe-container {
    background-color: #4d4d4d;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4%;
}

.mayannew-subscribe-heading {
    margin: 0;
    font-size: 24px;
}

.mayannew-subscribe-description {
    margin: 0.8% 0;
    font-size: 18px;
}

.mayannew-email-input-container {
    position: relative;
    margin-top: 1.5%;
    width: 30%;
}

.mayannew-email-input {
    width: 100%;
    padding: 3% 4%;
    font-size: 14px;
    border: none;
    outline: none;
    color: #4d4d4d;
}

.mayannew-email-icon {
    position: absolute;
    right: 0.4%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #4d4d4d;
    pointer-events: none;
}

// Footer
.mayannew-footer {
    background-color: #000;
    color: #fff;
    padding: 20px 6%;
}

.mayannew-sub-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    gap: 1.5%;

    .footer-logo {
        flex: 1;
        margin-top: 1.2%;
    }

    .footer-mayan-handicrafts,
    .footer-help,
    .footer-shop-by,
    .footer-contact {
        flex: 1;
    }

    .footer-title {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
    }

    .footer-links {
        list-style: none;
        padding: 0;
        font-size: 16px;
    }

    .footer-link {
        color: #4d4d4d !important;
        text-decoration: none;
        margin-bottom: 2.5%;
    }

    .footer-text {
        color: #4d4d4d;
        margin: 0;
    }

    .social-media,
    .social-media-title {
        margin-top: 5%;
    }

    .social-icon {
        color: #fff !important;
        text-decoration: none;
        margin-right: 1.5%;
        border-radius: 50%;
        background-color: #4d4d4d;
        padding: 2% 4%;
        font-size: 13px;
    }

    .social-icon i {
        font-size: 13px;
    }

}


// Loader section
.line-loader-container {
    position: relative;
    width: 100%;
    /* container's width */
    height: 40vh;
    /* container's height */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New Loader Section */
.line-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    max-height: 30vh;
}

.line-loader div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #4d4d4d;
    animation: bounce 0.6s infinite alternate;
}

/* Animation for the bouncing effect */
@keyframes bounce {
    0% {
        transform: translateY(0);
        opacity: 0.8;
    }

    50% {
        transform: translateY(-15px);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 0.8;
    }
}

/* Add animation delay for each dot to create the bouncing effect */
.line-loader div:nth-child(1) {
    animation-delay: 0s;
}

.line-loader div:nth-child(2) {
    animation-delay: 0.2s;
}

.line-loader div:nth-child(3) {
    animation-delay: 0.4s;
}

.line-loader div:nth-child(4) {
    animation-delay: 0.6s;
}

.line-loader div:nth-child(5) {
    animation-delay: 0.8s;
}


// Product Listing components style
.active-category {
    border-top: 3px solid #4d4d4d;
    font-weight: bold;
}

// Product Details components style 
.product-details-wrapper {
    .details-top {
        padding: 15px 0px 15px 0px;
    }

    .slider-nav {
        max-width: 400px;
        min-width: 300px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;

        .owl-thumb {
            img {
                opacity: 0.5;
            }

            background-color: #000;
            cursor: pointer;

            &:hover {
                background-color: transparent;
                border: 2px solid #f7f1f1;

                img {
                    opacity: 1;
                }
            }

            &.active {

                border: 2px solid #f7f1f1;
                background-color: transparent;

                img {
                    opacity: 1;
                }
            }
        }
    }

    .product-all-details {
        .product-name {
            font-size: 30px;
        }
    }

    .details-wrapper {
        .product-all-details {
            background-color: #fff;
            padding: 0px 15px 15px 15px;

            .price {
                font-size: 24px;
            }

            .row {
                padding: 15px;

                .col-left {
                    flex: 1;
                    padding-right: 15px;
                }

                .col-right {
                    min-width: 250px;

                    .add-to-cart {
                        margin-bottom: 30px;
                    }

                }
            }

            .description {
                padding-bottom: 15px;
            }

            .product-addtional-details {
                position: relative;
                padding-top: 15px;

                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 198px);
                    height: 2px;
                    background: #707070;
                    opacity: 0.2;
                    box-shadow: 0;
                    top: -10px;
                }
            }
        }

    }

}

.ngxImageZoomContainer {
    width: auto !important;
    height: auto !important;
    text-align: center !important;
}

.hob_contwid {
    display: flex !important;
}

.ngxImageZoomThumbnail {
    width: 450px !important;
    height: 600px !important;
    background-color: #eeebeb !important;
}

.hod_imagecol {
    width: 55% !important;
}


.titleCase-product {
    color: #4d4d4d !important;
    font-weight: 500 !important;
    font-family: 'Urbanist', sans-serif;
}

#prodName {
    margin-top: 0 !important;
    margin-bottom: 2% !important;
}

.mayannew-pro-name {
    color: #4d4d4d !important;
    margin-top: 2% !important;
    margin-bottom: 1% !important;
    font-family: 'Urbanist', sans-serif !important;
    font-weight: 700 !important;
}


.mayannew-prod-price {
    font-family: 'Urbanist', sans-serif;
    margin-top: 1% !important;
    color: #4d4d4d !important;
}

.delivery-text {
    font-family: 'Urbanist', sans-serif !important;
    margin-bottom: 2% !important;
    margin-top: 2% !important;
}

.delivery-input {
    height: 30px !important;
}

.delivery-btn {
    font-family: 'Urbanist', sans-serif;
    height: 35px !important;
}

.mayan_distruck {
    margin-top: 5% !important;
}

// Product Details right side content
.product-name {
    .product-name {
        color: #454545 !important;
        font-family: 'Urbanist', sans-serif !important;
        font-weight: bold !important;
        font-size: 27px !important;
        margin-bottom: 0 !important;
    }
}

#prodName {
    .product-list-price2-baby {
        color: #3d3c3c !important;
        font-weight: 700 !important;
    }
}

.deera-price-row {
    font-size: 20px !important;
}


// (After adding existing )
.login-section,
.checkout-forms {
    label {
        line-height: 0 !important;
    }
}

.app-checkout-login {
    label {
        line-height: 1.5% !important;
    }
}

.shipping-card-active {
    background-color: #f6f6f5 !important;
}



.mayannew-footerT {
    margin-top: 0 !important;
}

#productRow {
    padding-top: 0 !important;
}

.login-section {
    font-family: 'Urbanist', sans-serif !important;
    font-weight: 400 !important;
}

.fcolor {
    font-family: 'Urbanist', sans-serif !important;
    font-weight: 700 !important;
}

.signin-signup,
#dropdownMenuButton {
    color: #ffffff !important;
    font-size: 13px !important;
}

.dropdown-menu {
    background-color: #4d4d4d !important;
    color: white !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: #66686a !important;
}

.nav-item h6,
.my-pagination a {
    color: #4d4d4d !important;
}

.rounder-corner-nav .owl-theme .owl-nav {
    margin-left: 0 !important;
}

.owl-theme .owl-nav [class*=owl-] {
    background: transparent !important;
    border: none !important;
}

.rounder-corner-nav .owl-theme .owl-nav .owl-next,
.rounder-corner-nav .owl-theme .owl-nav .owl-prev {
    // width: 0 ;
    // height: 0 !important;
}

i .fa-angle-left,
i .fa-angle-right {
    font-size: 18px !important;
}

.popup-content {
    color: black !important;
}

.min-cart-product-wrapper {
    .row {
        flex-wrap: nowrap !important;

        .items-count {
            text-align: end !important;
        }
    }
}

.checkout-forms .checkout-label {
    color: #4d4d4d !important;
    font-family: 'Urbanist', sans-serif;
    font-weight: 700;
    font-size: 15px !important;
}

.mayannew-loc-label-title {
    margin-top: 12% !important;
    margin-left: -10% !important;
}

// .mayannew-loc-label {
//     border-bottom: 1px solid #4d4d4d !important;
//     box-shadow: none !important;
// }

.checkout-forms .mayannew-loc-label-title {
    position: absolute !important;
    top: 33px !important;
    left: -277px !important;
}

.form-check {
    .row {
        .sameAddressBox {
            width: 6% !important;
        }

        #sameBillingAddress,
        #sameAddress {
            margin-left: -10px !important;
        }
    }
}

// Login
.login-sub-text {
    font-family: Urbanist, sans-serif !important;
    font-weight: 500 !important;
}

.customer-container {
    font-family: Urbanist, sans-serif !important;

    .form-control {
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    .invalid-feedback {
        margin-top: 5% !important;
        font-weight: 500 !important;
    }

}

// My Accounts

#pills-tab h6 {
    font-family: 'Urbanist', sans-serif;
    font-weight: 700;
}

.profile-page {
    font-family: 'Urbanist', sans-serif;

    .heading {
        font-weight: 500;
        font-size: 22px !important;
    }

    .btn-primary {
        font-weight: 500; 
        padding: 1% 4%;
    }
}

.customer-registartion-form {
    font-family: 'Urbanist', sans-serif;

    .form-label {
        font-weight: 700;
    }

    .form-control,
    #country_code {
        font-size: 13px !important;   
        font-weight: 500;
    }
}

.customer-address-page {
    font-family: 'Urbanist', sans-serif;

    .card-header .btn, .card-body b, .card-body span{
        font-size: 13px;
    }
}

// My orders 
app-my-orders {
    font-family: 'Urbanist', sans-serif !important;

    .text-primary {
       font-weight: 700;
    }

    .my-oders {
        .box-orders {
            th {
                font-weight: 600;  
            }
            td {
                font-weight: 500;
                font-size: 14px !important;  
            }
        }
    }
}

